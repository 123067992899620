
@import '../../assets/css/style.styl';

.btn-payment-layout
    width 50%
    padding 0 1rem
    @media medium
        width 100%
        padding 0.5rem 0
    @media small
        width 100%
        padding 0.5rem 0
.btn-payment
    width 100%
    background white
    cursor pointer
    border 1px solid $color-bnn-border-dark-btn
    border-radius: 5px;
    height: auto;
    &:hover
         border-color: $color-dark-gray;
    .text-btn-payment
        font-size 1rem
    @media medium
        .text-btn-payment
            font-size 1.2rem
    @media small
        .text-btn-payment
            font-size 0.8rem
.btn-payment.select
     border-color: $color-bnn;
     box-shadow: 0px 2px 3px #00000029;
.btn-payment.has-error
     border-color: red;
