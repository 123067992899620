
@import '../../assets/css/style.styl';

.delivery
    display flex
    height 100%
    align-items stretch
    margin-top 3rem
    @media medium
        flex-wrap wrap
    @media small
        flex-wrap wrap
.delivery-layout
    display flex
    width 50%
    padding 0 1rem
    @media medium
        width 100%
        padding 0.5rem 1rem
    @media small
        width 100%
        padding 0.5rem 1rem
.btn-delivery
    display flex
    align-items center
    width 100%
    height 100%
    background white
    cursor pointer
    border-radius: 5px;
    border 1px solid $color-bnn-border-dark-btn

    &:hover
     border-color: $color-dark-gray;

.btn-delivery.select
         border-color: $color-bnn;
         box-shadow: 0px 2px 3px #00000029;
.btn-delivery.has-error
    border-color: red
.btn-delivery.disabled
    opacity 0.6
    cursor not-allowed
.deli-detail
    font-size 1.125rem
    @media small
       font-size 0.8rem
