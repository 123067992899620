
@import '../../assets/css/style.styl';
.customer-form
    background $color-bnn-gray
    padding-top 1rem
    padding-bottom 3rem
    margin-top 4rem
    width 100%
.form-customer-layout
    width 80%
    @media medium
      width 100%
      padding 0 1rem
    @media small
      width 100%
      padding 0 1rem
.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
