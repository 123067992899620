
@import '../../assets/css/style.styl'
.pointer
    cursor pointer
.payment
    background white
    margin-top 1rem
.p-layout
    display flex
    flex-direction column
    align-content center
    align-items center
.payment-content-2
    width 80%
    @media medium
        width 100%
        padding 0 1rem
    @media small
        width 100%
        padding 0 1rem
.payment-content
    background black
    color white
    width 80%
    padding 1rem
    @media medium
        width 100%
    @media small
        width 100%
.channel-payment
    display flex
    align-items center
    margin-top 3rem
    width 100%
    flex-wrap wrap
.btn-bnn-submit
    background $color-bnn
    border-radius 30px;
    text-align center
    box-shadow: 0px 4px 6px #00000029;
    padding 1rem 6rem
    max-width 350px
    font-weight bold
    cursor pointer
    @media small
      padding 0.6rem 6rem
    @media medium
      padding 1rem 6rem
.btn-bnn-submit.disabled
    background $color-gray-100
    opacity 0.7
    cursor not-allowed
.text-detail-payment-delivery
    font-size 1rem
    @media medium
        font-size 0.8rem
    @media small
        font-size 0.8rem
.isLoading
  cursor not-allowed !important
  opacity 0.6
