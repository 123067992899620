
@import '../../assets/css/style.styl';
.footer-bnn-top
  height: 40px
  background-color: $color-gray-400
  z-index 10
  @media small
    font-size 0.7rem
.footer-bnn-bottom
  height: 200px
  background-color: black
  z-index 10
  color white
  @media small
    font-size 0.7rem
