
@import '../../assets/css/style.styl'

.model-content
  display flex
  justify-content center
  flex-wrap wrap
  width 80%
  @media medium
    width 100%
  @media small
    width 100%


.model-item
        width 50%
        padding 1rem

@media medium
    .model-item
        width 100%
        padding 0.75rem 0

@media small
    .model-item
        width 100%
        padding 0.75rem 0

.col-model {
  position: relative;
}

.btn-model {
  color: $color-dark-gray;
  display: flex;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: 4px;
  border: 1px solid;
  flex-direction column
  border-color: $color-bnn-border-dark-btn;
  justify-content: center;
  align-content: items-center;
  // min-height: 203px;
  width 100%;
  height 100%;
  gap 2rem;

  ul {
    text-align: left;
    margin-top: 15px;
    margin-left: 10px;

    li:last-child {
      position: absolute;
      list-style: none;
      bottom: -60px;
      left: 20%;
      width: 60%;
      text-align: center;
      background: #ffd400;
      border-radius: 10px;
      padding: 5px 0;
    }
  }

  &:hover {
    border-color: $color-dark-gray;
  }

  // .selected
  // border-color: $color-dark-gray
  text-align: center;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  cursor: pointer;

  &.selected {
    border-color: $color-bnn-border;
    box-shadow: 0px 2px 3px #ffd400;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      border-color: $color-gray-border !important;
    }
  }
}
