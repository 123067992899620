
@import '../../assets/css/style.styl';
.bundle
    width 80%
    @media medium
        width 100%
    @media small
        width 100%

.accessories-content
    margin-top 24px
    display flex
    flex-wrap wrap
    justify-content center
    @media medium
       flex-wrap wrap
    @media small
       flex-wrap wrap


.accessories-layout
    width 33.33333%
    padding 1rem
    @media medium
       width 100%
       padding 0 1rem
    @media small
       width 100%
       padding 0 1rem

.hr-title-bundle
    padding 1rem
    margin-top 1.5rem

.img-bundle
  width auto;
  max-height 100px
  max-width 110%
  min-width 60px
  @media medium
    max-height 60px




.btn-counter {
  border: 1px solid;
  border-color: $color-gray-border;
  border-radius: 4px;
  padding: 0rem 0.2rem;
}

.btn-accessories {
  cursor: pointer;
  color: $color-dark-gray;
  border: 1px solid;
  border-color: $color-bnn-border-dark-btn;

  &:hover {
    border-color: $color-dark-gray;
  }

  width: 100%;
  margin-bottom: 1.2rem;
  border-radius: 4px;

  .checkbox {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $color-gray-border;
  }

  &.selected {
    border-color: $color-bnn-border;
    box-shadow: 0px 2px 3px #00000029;

    .checkbox {
      border: none;
      background: $color-bnn-border;
    }
  }
  &.disabled {
    opacity 0.6
    cursor not-allowed
  }
}

.btn-counter {
  &-add {
    width: 25px;
    height: 25px;
    text-align: center;
    align-items: start;

    &::after {
      color: $color-dark-gray-medium;
      content: '\002B';
      font-size: 20px;
      align-self: center;
      justify-content: center;
      margin-top: -3px;
      display: flex;
    }
  }

  &-remove {
    width: 25px;
    height: 25px;
    text-align: center;
    align-items: start;

    &::after {
      color: $color-dark-gray-medium;
      content: '\2212';
      font-size: 20px;
      align-self: center;
      justify-content: center;
      margin-top: -3px;
      display: flex;
    }
  }
}

.span-counter {
  padding: 0rem 0.2rem;
  align-self: center;
}
