
@import '../../assets/css/style.styl'
.slider-arrow
    width 35px
    height 35px
    @media medium
       width 60px
       height 60px
.SVGInline
    cursor pointer
.img-ps5
    margin-bottom 0
    @media medium
        margin-bottom 0
        width 30%
    @media small
        margin-bottom 0
        width 40%
.slider-playstation
    // background: transparent linear-gradient(301deg, #181C1F 0%, #2D333B 100%) 0% 0% no-repeat padding-box;
    background white
    width 80%
    // height 450px
    display flex
    flex-direction column-reverse
    margin-top 150px
    overflow hidden
    @media small
        flex-direction column-reverse
        height auto
        width 100%
        margin-top 120px
    @media medium
        flex-direction column-reverse
        height auto
        width 100%
        margin-top 120px
    align-items center
    .content
        display flex
        justify-content center
        width 100%
        @media medium
            width 100%
        @media small
            width 100%
    .content-left
        display flex
        justify-content flex-start
        width 50%
        @media medium
            width 100%
        @media small
            width 100%
    .content-right
        display flex
        justify-content flex-end
        width 50%
        @media medium
            width 100%
        @media small
            width 100%
.text-exclusive
    background $color-bnn
    font-size 2.2rem
    font-weight bold
    padding 0.25rem 1.3rem
    border-radius: 10px;
    @media medium
      font-size 2rem
    @media small
      font-size 1.2rem
.slider-col
    display flex
    flex-direction column
    font-size 2.2rem
    align-content center
    @media medium
        margin-top 2rem
    @media small
        margin-top 1.5rem
.text-preorder
    font-size 2.2rem
    margin-left 0.2rem
    @media medium
      font-size 2rem
    @media small
      font-size 1.2rem
.text-ps5
    font-size 2.2rem
    margin-left 0.2rem
    @media medium
      font-size 2rem
    @media small
      font-size 1.2rem
.preview-img
    width 100%
    padding 4rem 0
    // height 800px
    object-fit contain
    @media medium
        // height 600px
        margin -3rem 0
    @media small
        // height 450px
        margin -3rem 0
.btn-more
    background $color-bnn
    border-radius 30px;
    text-align center
    box-shadow: 0px 4px 6px #00000029;
    padding 0.5rem 4rem
    max-width 350px
    font-weight bold
    cursor pointer
    font-size 1rem
    @media small
      font-size 0.8rem
      padding 0.6rem 4rem
    @media medium
      padding 0.6rem 4rem
.thumbnail
  border: none;
  object-fit: contain;
  border-radius: 4px;
  padding: 0.1rem;
  cursor: pointer;
  margin: 0.2rem;
  width 80px
  height 80px
  @media medium
     width 120px
     height 120px
  @media small
     width 50px
     height 50px
  padding 0.4rem

  &:hover
    border: 1px solid $color-dark-gray
.thumbnail.selected
    border: 1px solid $color-bnn-border-light
.slider-line
    height 10px
    background $color-gray-100

.fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}
